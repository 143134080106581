import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useTable, usePagination } from "react-table";
import { Dropdown, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import moment from "moment";

import {
  getBulkInviteTemplate,
  getBulkInvitesList,
  createBulkPatients,
} from "../../store/actions/addPatient";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { isValidDate } from "../../methods";

const UploadsTable = ({ columns, data, t }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="row my-4">
        <div className="col-6 d-flex justify-content-start">
          <Dropdown className="d-inline-block">
            <Dropdown.Toggle as="span">
              <span style={{ fontWeight: "100 !important" }}>{pageSize}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {[5, 10, 15].map((pageSize) => (
                <Dropdown.Item
                  key={pageSize}
                  onClick={() => setPageSize(pageSize)}
                >
                  {pageSize}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <span className="ms-2" style={{ lineHeight: "1.4rem" }}>
            {" "}
            {t("pracpl_line_6")}
          </span>
        </div>
        <div className="col-6 d-flex flex-row justify-content-end text-center">
          {t("page")}
          <strong className="mx-2">
            {pageIndex + 1} {t("of")} {pageOptions.length}
          </strong>
          <div
            className={`pg-link mx-2 fs-4 lh-1 ${canPreviousPage ? "" : "opacity-25"
              }`}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </div>
          <div
            className={`pg-link mx-2 fs-4 lh-1 ${canNextPage ? "" : "opacity-25"
              }`}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </div>
        </div>
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

const AddPatientBulkPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const userType = useSelector((state) => state.auth.userType);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );

  const [templateLoading, settemplateLoading] = useState(false);
  const [isUploading, setisUploading] = useState(false);
  const [bulkList, setbulkList] = useState([]);
  const [bListLoading, setbListLoading] = useState(false);
  const filePicker = useRef(null);
  const hData = {
    currentPracticeID,
    currentPractice,
    currentUserId,
    userType,
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadTemplate = (token) => dispatch(getBulkInviteTemplate(token, hData));
  const loadBulkListData = (token) =>
    dispatch(getBulkInvitesList(token, hData));
  const uploadBulkPatientFile = (token, data) =>
    dispatch(createBulkPatients(token, hData, data));

  const loadData = () => {
    setbListLoading(true);
    loadBulkListData(token)
      .then((res) => {
        setbulkList(res.payload);
      })
      .catch((err) => {
        toast.error(t("alert_swr"));
      })
      .finally(() => {
        setbListLoading(false);
      });
  };

  const onFileSelect = (e) => {
    if (e.target.files.length) {
      setisUploading(true);
      const ptFile = e.target.files[0];
      const fData = new FormData();
      fData.append("file", ptFile);
      uploadBulkPatientFile(token, fData)
        .then((res) => {
          setbListLoading(true);
          if (!res.error) {
            toast.success(res.payload.message);
          }
        })
        .finally(() => {
          setisUploading(false);
          resetFilePicker();
          setTimeout(loadData, 1000);
        });
    }
  };

  const resetFilePicker = () => {
    if (filePicker && filePicker.current) {
      filePicker.current.value = "";
    }
  };

  const getTemplate = () => {
    settemplateLoading(true);
    loadTemplate(token)
      .then((res) => {
        window.open(res.payload.pre_template_s3_url, "_blank");
      })
      .catch((err) => {
        toast.error(t("alert_swr"));
      })
      .finally(() => {
        settemplateLoading(false);
      });
  };

  const uTableCols = React.useMemo(
    () => [
      {
        Header: t("uploaded_date"),
        accessor: "uploaded_date",
        Cell: ({ value }) => {
          return (
            <span>
              {/* {moment(cell.value).format("MMM DD YYYY, hh:mm A")} */}
              {isValidDate(value)
                ? format(new Date(value), "MMM do, yyyy", {
                  locale: i18n.language === "fr" ? fr : undefined,
                })
                : value}
            </span>
          );
        },
      },
      {
        Header: t("uploaded_by"),
        accessor: "uploaded_by",
      },
      {
        Header: t("success"),
        accessor: "status.success_count",
      },
      {
        Header: t("failed"),
        accessor: "status.failed_count",
      },
      {
        Header: t("error_log"),
        accessor: "error_log_url",
        Cell: ({ cell }) => {
          return (
            <div style={{ minHeight: 58 }}>
              {cell.value && (
                <button
                  className="btn btn-primary text-light px-5 my-1 mx-1"
                  onClick={() => {
                    window.open(cell.value, "_blank");
                  }}
                  id="done-btn__addpatient"
                >
                  {t("error_log")}
                </button>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>
          {t("puregenomics_1")} - {t("pat_bulk_up_add")}
        </title>
      </Helmet>
      <div className="container-xl pt-5 px-lg-0 px-4">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() => navigate("/practice/dashboard")}
        >
          <i className="fas fa-chevron-left mr-2"></i> {t("dashboard")}
        </div>
        <div className="mt-2 pg-title text-center">{t("pat_bulk_up_add")}</div>
        <div className="row mb-3 justify-content-between gx-4">
          <div className="col-7" style={{ textAlign: "justify" }}>
            {t("pat_bulk_up_desc")}
          </div>
          <div className="col my-auto">
            <div>
              <label
                className="btn btn-primary text-light px-2 my-1 mx-1 w-100"
                id="done-btn__addpatient"
              >
                {isUploading ? (
                  <div
                    className="w-100 d-flex align-items-center justify-content-center"
                    style={{ minHeight: "64px" }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <>{t("pat_bulk_up_upload")}</>
                )}
                <input
                  className="d-none"
                  id="pt-bulk-file"
                  type="file"
                  accept=".xlsx"
                  ref={filePicker}
                  onChange={onFileSelect}
                  disabled={isUploading}
                />
              </label>
              <div className="text-center mt-2" style={{ fontSize: 12 }}>
                {t("pat_bulk_up_format")}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
            </div>
            <div className="mt-2 text-center" onClick={getTemplate}>
              <div className="pg-link me-0 fs-6">
                {templateLoading ? (
                  <Loader />
                ) : (
                  <>{t("pat_bulk_up_download")}</>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {bListLoading ? (
            <div className="mt-2 mb-4 fs-4 text-center pg-link">
              <Loader />
            </div>
          ) : bulkList && bulkList.length ? (
            <UploadsTable columns={uTableCols} data={bulkList} t={t} />
          ) : (
            <div className="mt-2 mb-4 fs-4 text-center">
              {t("pat_bulk_up_no_files")}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddPatientBulkPage;
