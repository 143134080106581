import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as PgLogo } from "../../assets/PG_Logo.svg";
import { ReactComponent as PiLogo } from "../../assets/PureInsight_Logo_RGB_u1.svg";
import { toggleSidebar } from "../../methods/sidenav";
import "../../styles/header.css";
import NavButtons from "../NavButtons";
import { logoutAction } from "../../methods/auth_methods";

function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const firstName = useSelector((state) => state.auth.firstName);
  const isStaff = useSelector((state) => state.auth.isStaff);
  const lastName = useSelector((state) => state.auth.lastName);
  const practices = useSelector((state) => state.auth.practices);
  const userType = useSelector((state) => state.auth.userType);
  const eCommerce = useSelector((state) => state.auth.enableECommerce);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const showSRPTable = useSelector((state) => state.auth.showSRPTable);
  const language = useSelector((state) => state.auth.language);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );

  let nagivateToVd = (e) => {
    navigate("/practice/rialto_management_new", {
      state: {
        path: "/pure_vd",
        browseCatalog: false,
      },
    });
  };

  return (
    <div
      className="w-100 position-fixed bg-white"
      style={{ top: 0, left: 0, zIndex: 10 }}
    >
      <nav className="container-fluid mx-0 navbar py-3">
        <div className="container-xl px-0">
          {/* menu button */}
          <div className="icon-one d-md-none">
            <div
              className="hamburger hamburger-one"
              onClick={toggleSidebar}
            ></div>
          </div>

          {/* Logo */}
          <div
            className="m-auto m-md-0 cursor-pointer pg-logo"
            onClick={() => navigate("/")}
          >
            {/* <PgLogo fill="#fff" fontSize="100px" /> */}
            {i18n.language == "en_gb" ? (
              <PgLogo fill="#fff" fontSize="100px" />
            ) : (
              <PiLogo fill="#fff" fontSize="100px" />
            )}
          </div>
          {isAuth && (
            <div>
              <span
                className="cursor-pointer pg-text__bold text-decoration-none textc-primary mx-2 d-none d-lg-inline-block"
                style={{ verticalAlign: "top" }}
              >
                {firstName + " " + lastName}
              </span>
              <Dropdown className="d-inline-block border-radius">
                <Dropdown.Toggle as="i" bsPrefix="text-danger">
                  <i className="fas fa-user-circle cursor-pointer user-icon textc-primary"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="border-radius">
                  {practices?.length >= 2 && (
                    <>
                      <Dropdown.Item
                        onClick={() => navigate("/users/domain_pl")}
                      >
                        <i
                          className="fas fa-user-circle"
                          style={{ color: "var(--pg_primary)", fontSize: 25 }}
                        ></i>{" "}
                        <span style={{ verticalAlign: "top" }}>
                          {t("header_btn_14")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}
                  {/* {language === "en" && !isStaff && (
                    <div>
                      <Dropdown.Item
                        onClick={() => navigate("/practice/rialto_management")}
                      >
                        <i
                          className="fas fa-user-circle"
                          style={{ color: "var(--pg_primary)", fontSize: 25 }}
                        ></i>{" "}
                        <span style={{ verticalAlign: "top" }}>
                          {t("header_btn_15")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </div>
                  )} */}
                  {language === "en" && !isStaff && (
                    <div>
                      <Dropdown.Item onClick={() => nagivateToVd()}>
                        <i
                          className="fas fa-user-circle"
                          style={{ color: "var(--pg_primary)", fontSize: 25 }}
                        ></i>{" "}
                        <span style={{ verticalAlign: "top" }}>
                          {t("header_btn_15")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </div>
                  )}
                  {!isStaff && (
                    <>
                      <Dropdown.Item
                        onClick={() => navigate("/practice/staff")}
                      >
                        <i
                          className="fas fa-user-circle"
                          style={{ color: "var(--pg_primary)", fontSize: 25 }}
                        ></i>{" "}
                        <span style={{ verticalAlign: "top" }}>
                          {t("header_btn_17")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {!isStaff && (
                    <>
                      <Dropdown.Item
                        onClick={() => navigate("/practice/account")}
                      >
                        <i
                          className="fas fa-user-circle"
                          style={{ color: "var(--pg_primary)", fontSize: 25 }}
                        ></i>{" "}
                        <span style={{ verticalAlign: "top" }}>
                          {t("header_btn_16")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {isStaff && (
                    <>
                      <Dropdown.Item onClick={() => navigate("/staff/account")}>
                        <i
                          className="fas fa-database"
                          style={{ color: "var(--pg_primary)", fontSize: 25 }}
                        ></i>{" "}
                        <span style={{ verticalAlign: "top" }}>
                          {t("header_btn_16")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {language === "en_gb" && !isStaff && (
                    <>
                      <Dropdown.Item
                        onClick={() => navigate("/practice/manage_data")}
                      >
                        <i
                          className="fas fa-database"
                          style={{ color: "var(--pg_primary)", fontSize: 25 }}
                        ></i>{" "}
                        <span style={{ verticalAlign: "top" }}>
                          {t("header_btn_18")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  <Dropdown.Item onClick={logoutAction}>
                    {" "}
                    <i
                      className="fas fa-sign-out-alt"
                      style={{ color: "var(--pg_primary)", fontSize: 25 }}
                    ></i>{" "}
                    <span style={{ verticalAlign: "top" }}>
                      {t("header_btn_1")}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </nav>

      <NavButtons show="provider" />
    </div>
  );
}
export default Header;
