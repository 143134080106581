import React, { useState, useEffect, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import toast from "react-hot-toast";
import _ from "lodash";
import moment from "moment";

import { validateEmail } from "../../methods";
import { months, years } from "../../methods/constant";
import TextInput from "../../components/TextInput";
import PgSelect from "../../components/PgSelect";
import {
  getCountries,
  selfRegisterPatient,
} from "../../store/actions/addPatient";
import OptForSearch from "../../components/OptForSearch";

const PatientSelfRegister = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [practice_code] = useSearchParams();
  const pCodeRef = useRef();

  const [pCode, setpCode] = useState("");
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [skincolor, setSkinColor] = useState("");
  const [country, setCountry] = useState("");
  const [pwd, setPwd] = useState("");
  const [confPwd, setconfPwd] = useState("");
  const [showCountry, setShowCountry] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [c1, setC1] = useState(false);
  const [c2, setC2] = useState(false);
  const [c3, setC3] = useState(false);
  const [c4, setC4] = useState(false);
  const [c5, setC5] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [statusText, setStatusText] = useState("");

  const [pCodeError, setpCodeError] = useState(false);
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailAvailabilityError, setEmailAvailabilityError] = useState(false);
  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [skinColorError, setskinColorError] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [pwdError, setpwdError] = useState(false);
  const [conPwdError, setconPwdError] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [isCA, setIsCA] = useState(false);
  const [showOptSearchModel, setShowOptSearchModel] = useState(false);
  const [pCodeX, setpCodeX] = useState("");
  const [fromPS, setfromPS] = useState(false);

  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const registerPatient = (data) =>
    dispatch(selfRegisterPatient(data, currentPractice));
  const loadCountryList = () => dispatch(getCountries("", "", currentPractice));

  const location = useLocation();
  useEffect(() => {
    if (
      i18n.language !== "en" &&
      i18n.language !== "ca" &&
      i18n.language !== "fr"
    )
      navigate("/404");
    const queryParams = new URLSearchParams(location.search);
    const practiceCode = queryParams.get("practice_code");
    const showPS = queryParams.get("show_ps");
    console.log({ showPS, queryParams });
    if (practiceCode && pCodeRef?.current) {
      setpCode(practiceCode);
      pCodeRef.current.disabled = true;
    } else if (
      (i18n.language === "ca" || i18n.language === "fr") &&
      showPS !== "true"
    ) {
      navigate("/404");
    }
    if (
      (i18n.language === "ca" || i18n.language === "fr") &&
      showPS === "true"
    ) {
      setShowOptSearchModel(true);
      setfromPS(true);
    }

    handleCountry();
  }, []);

  // useEffect(() => {
  //   if (pCodeRef.current) {
  //     pCodeRef.current.value = "123123";
  //   }
  // }, [pCode]);

  useEffect(() => {
    // setpwdError(false);
    setC1(false);
    setC2(false);
    setC3(false);
    setC4(false);
    setC5(false);
    setProgressValue(0);
    setStatusText("");

    if (pwd.length > 9) {
      setC1(true);
      setProgressValue(75);
      setStatusText(t("prp_line_16"));
    } else if (pwd.length > 7) {
      setProgressValue(50);
      setStatusText(t("prp_line_16"));
    } else if (pwd.length > 3) {
      setStatusText(t("prp_line_17"));
      setProgressValue(25);
    } else if (pwd.length < 3) {
      setStatusText("");
      setProgressValue(0);
    }

    if (/(?=.*[A-Z])/.test(pwd)) setC2(true);
    if (/(?=.*[a-z])/.test(pwd)) setC3(true);
    if (/(?=.*\d)/.test(pwd)) setC4(true);
    if (/(?=.*[\W_])/.test(pwd)) setC5(true);

    const isPasswordValid = c1 && c2 && c3 && c4 && c5;
    if (pwd.length > 0) {
      if (isPasswordValid) {
        setStatusText(t("prp_line_15"));
        setProgressValue(100);
        // setpwdError(false);
        if (pwd === confPwd) setconPwdError(false);
      } else {
        // setpwdError(true);
      }
    }

    if (pwd !== confPwd && confPwd.length > 0) {
      setconPwdError(true);
    }
    // console.log({ pwd, confPwd, c1, c2, c3, c4, c5 });
  }, [pwd, confPwd, c1, c2, c3, c4, c5]);

  useEffect(() => {
    if (isValidForm) {
      handleDone();
    }
  }, [isValidForm]);

  const handleCountry = () => {
    const origin = window.location.origin;
    if (origin.endsWith(".co.uk")) {
      setShowCountry(true);
      loadCountry();
    } else if (origin.endsWith(".ca")) {
      setCountry("Canada");
      setIsCA(true);
    } else {
      setCountry("United States");
    }
  };

  const loadCountry = () => {
    loadCountryList()
      .then((res) => {
        if (res && res.payload.countries.length) {
          const cList = [...res.payload.countries];
          _.remove(cList, function (n) {
            return n === "United States" || n === "Canada";
          });
          setCountryList(cList);
        }
      })
      .catch((err) => {
        console.log("Error loading countries list");
      });
  };

  const handleDone = async () => {
    const patDetails = {
      patient: {
        practice_code: pCode,
        first_name: fName,
        last_name: lName,
        full_name: `${fName} ${lName}`,
        email: email,
        patient_type: "real",
        status: false,
        dob: moment(age).format("YYYY-MM-DD"),
        gender: gender,
        skin_color: skincolor,
        country: country,
        password: pwd,
        password_confirmation: confPwd,
        from_provider_search: fromPS,
      },
    };
    registerPatient(patDetails)
      .then((res) => {
        if (res.payload.id) {
          toast.success("Registered successfully");
          navigate("/");
        } else if (res.payload.email_error) {
          toast.error("Email already taken");
        }
      })
      .catch((err) => toast.error("Something went wrong"))
      .finally(() => {
        setIsValidForm(false);
      });
  };

  const handleInput = (value, errorState, updateValueState) => {
    if (value.length === 0) {
      errorState(true);
    } else {
      errorState(false);
    }
    updateValueState(value);
  };

  const onDone = () => {
    setEmailAvailabilityError(false);
    if (email.length === 0 || !validateEmail(email)) {
      setEmailError(true);
      setEmailErrorMessage(
        email.length === 0 ? "Email is required" : "Enter valid Email"
      );
    }
    if (pCode.length === 0) setpCodeError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (age.length === 0) setAgeError("Date of Birth is required");
    if (age.length !== 0 && !_.isDate(age)) setAgeError(t("pracand_age_error"));
    if (gender.length === 0) setGenderError(t("pracand_gender_error"));
    if (skincolor.length === 0) setskinColorError(t("Skin color is required"));
    if (country.length === 0) setCountryError("Country is required");
    if (c1 && c2 && c3 && c4 && c5 && pwd.length >= 10) {
      setpwdError(false);
    } else {
      setpwdError(true);
    }
    if (confPwd.length === 0 || pwd !== confPwd) setconPwdError(true);

    if (
      email.length !== 0 &&
      validateEmail(email) &&
      pCode.length !== 0 &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      _.isDate(age) &&
      ageError.length <= 0 &&
      gender.length !== 0 &&
      skincolor.length !== 0 &&
      country.length !== 0 &&
      pwd.length >= 10 &&
      pwd === confPwd &&
      !pwdError &&
      c1 &&
      c2 &&
      c3 &&
      c4 &&
      c5
    ) {
      setIsValidForm(true);
    } else {
      window.scrollTo(0, 100);
    }
  };

  const onCancel = () => {
    navigate("/");
  };

  const renderPwdConditions = () => {
    const conditions = [
      {
        pass: c1,
        label: "Minimum of 10 characters",
      },
      {
        pass: c2,
        label: t("prp_line_10"),
      },
      {
        pass: c3,
        label: t("prp_line_11"),
      },
      {
        pass: c4,
        label: t("prp_line_12"),
      },
      {
        pass: c5,
        label: t("prp_line_20"),
      },
    ];
    return (
      <>
        <div>{t("prp_line_9")}</div>
        {conditions.map((c, idx) => {
          return (
            <div
              className={`my-3 text-${c.pass ? "success" : "secondary"}`}
              key={idx}
            >
              <i className={`${c.pass ? "fas" : "far"} fa-check-circle fs-5`} />
              <span className="ms-3">{c.label}</span>
            </div>
          );
        })}
      </>
    );
  };

  const renderPassProgress = () => {
    const prgClass =
      progressValue <= 50 ? "danger" : progressValue <= 75 ? "info" : "success";
    return (
      <div>
        {t("prp_line_13")} <span className={prgClass}> {statusText}</span>
        <div className="pe-lg-3 me-lg-3">
          <ProgressBar
            now={progressValue}
            variant={prgClass}
            style={{ height: 10, width: "100%" }}
            className="border-radius my-3"
          />
        </div>
      </div>
    );
  };

  const renderPatientFields = () => {
    return (
      <>
        <TextInput
          label={t("pat_self_prac_code")}
          showLabel={false}
          ref={pCodeRef}
          required={true}
          optional={false}
          id="p-code__addpatient"
          placeholder={t("pat_self_prac_code")}
          value={fromPS ? pCodeX : pCode} // show code with xx code is from provider search
          error={pCodeError}
          type="text"
          errorMessage={`${t("pat_self_prac_code")} is required`}
          inputHandler={(e) => {
            let reg = /^[0-9a-zA-Z_]+$/;
            if (reg.test(e.target.value) || e.target.value === "") {
              handleInput(e.target.value, setpCodeError, setpCode);
            }
          }}
        />
        <TextInput
          label={t("pracand_fname")}
          showLabel={false}
          required={true}
          optional={false}
          id="f-name__addpatient"
          placeholder={t("pracand_fname_ph")}
          value={fName}
          error={fNameError}
          type="text"
          errorMessage={t("pracand_fname_error")}
          inputHandler={(e) => {
            let reg = /^[0-9a-zA-Z_]+$/;
            if (reg.test(e.target.value) || e.target.value === "") {
              handleInput(e.target.value, setfNameError, setfName);
            }
          }}
        />
        <TextInput
          label={t("pracand_lname")}
          showLabel={false}
          required={true}
          optional={false}
          id="l-name__addpatient"
          placeholder={t("pracand_lname_ph")}
          value={lName}
          error={lNameError}
          errorMessage={t("pracand_lname_error")}
          inputHandler={(e) => {
            let reg = /^[0-9a-zA-Z_]+$/;
            if (reg.test(e.target.value) || e.target.value === "") {
              handleInput(e.target.value, setlNameError, setlName);
            }
          }}
        />
        <div className="my-2 dateVal">
          <ReactDatePicker
            renderCustomHeader={({ date, changeYear, changeMonth }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <select
                  value={date?.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
            onChange={(date) => {
              setAge(date);
              setAgeError(!_.isDate(date) ? t("pracand_age_error") : "");
            }}
            style={{ width: "100%" }}
            value={age}
            dateFormat="MM/dd/yyyy"
            onChangeRaw={() => {
              setAge("");
            }}
            selected={age}
            className={_.isEmpty(ageError) ? "dateFld" : "dateFld error"}
            placeholderText="Enter DOB MM/DD/YYYY"
            minDate={new Date("1900-01-01")}
            maxDate={new Date()}
            popperPlacement="botom"
            popperModifiers={{
              flip: {
                behavior: ["bottom"],
              },
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            }}
          />
          <div
            className="text-danger mx-2 my-1 transition-all"
            style={
              !_.isEmpty(ageError)
                ? { opacity: 1 }
                : { opacity: 0, minHeight: "24px" }
            }
          >
            {!_.isEmpty(ageError) && (
              <div
                dangerouslySetInnerHTML={{
                  __html: `<i class="fas fa-info-circle"></i>` + ageError,
                }}
              ></div>
            )}
          </div>
        </div>
        <PgSelect
          label={t("pracand_gender")}
          style={{
            marginTop: "20% !important",
            marginBottom: "20% !important",
          }}
          showLabel={false}
          required={true}
          optional={false}
          id="gender__addpatient"
          setSelectedItem={setGender}
          selectedItem={gender}
          placeholder={t("pracand_gender_ph")}
          practiceAddPatient={true}
          options={["Male", "Female", "Intersex"]}
          error={gender !== "" ? "" : genderError}
        />
        <PgSelect
          label={t("pat_self_skin")}
          showLabel={false}
          required={true}
          optional={false}
          id="skincolor__addpatient"
          setSelectedItem={setSkinColor}
          selectedItem={skincolor}
          placeholder={t("pat_self_skin_full")}
          options={["Dark", "Medium", "Light"]}
          practiceAddPatient={true}
          error={skincolor !== "" ? "" : skinColorError}
        />
        <div className="my-2">
          <TextInput
            label={t("pracand_email")}
            showLabel={false}
            emailField={true}
            required={true}
            optional={false}
            id="email-input__addpatient"
            placeholder={t("pracand_email_ph")}
            value={email}
            error={emailError}
            errorMessage={emailErrorMessage || "Email is required"}
            inputHandler={(e) => {
              if (e.target.value.length < 0 && !validateEmail(e.target.value)) {
                setEmailError(true);
              } else {
                setEmailAvailabilityError(false);
                setEmailError(false);
                setEmail(e.target.value);
              }
            }}
            style={{ position: "relative" }}
          />
          {emailAvailabilityError && (
            <div
              className="email-error-wrapper text-danger"
              dangerouslySetInnerHTML={{ __html: t("pracand_email_error") }}
            />
          )}
        </div>
        <PasswordInput
          placeholder={t("pat_self_pass")}
          error={pwdError}
          errorMessage={
            pwdError
              ? pwd.length === 0
                ? t("password_val_err_1")
                : pwd.length < 10
                  ? t("password_val_err_2")
                  : pwdError
                    ? t("pat_self_pass_invalid")
                    : ""
              : ""
          }
          value={pwd}
          inputHandler={(e) => {
            handleInput(e.target.value, setpwdError, setPwd);
          }}
          id="pwd__addpatient"
          required={true}
          slim={true}
        />
        <PasswordInput
          placeholder={t("pat_self_pass_confirm")}
          error={conPwdError}
          errorMessage={
            confPwd.length === 0
              ? "Password is required"
              : "Both passwords should be the same"
          }
          value={confPwd}
          inputHandler={(e) => {
            handleInput(e.target.value, setconPwdError, setconfPwd);
          }}
          id="confPwd__addpatient"
          required={true}
          slim={true}
        />
        {showCountry && (
          <PgSelect
            label={t("pat_self_country")}
            showLabel={false}
            required={true}
            optional={false}
            id="country__addpatient"
            setSelectedItem={setCountry}
            selectedItem={country}
            placeholder={t("pat_self_country")}
            options={countryList}
            practiceAddPatient={true}
            error={country !== "" ? "" : countryError}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("puregenomics_1")} - Patient Self Register</title>
      </Helmet>
      <div className="mt-2 pg-title text-center">{t("prelogin_btn_3")}</div>
      <div className="mt-2 mb-2 pg-heading text-center">
        {t("prac_register_enter_info_text")}
      </div>

  <div className="container mt-4 mx-auto">
      <div className="row">
        <div className="col-md-6">{renderPatientFields()}</div>
        <div className="col-md-6">
          <div className="mt-2 ms-4 ps-1 fs-5">{renderPwdConditions()}</div>
          <div className="mt-2 ms-4 ps-1 fs-5">{renderPassProgress()}</div>
        </div>
      </div>
      <div className="pb-4 pe-2">
        <div className="d-flex justify-content-between justify-content-md-start">
          <div className="">
            <button
              className="btn btn-secondary text-light px-4 my-1"
              onClick={onCancel}
              id="cancel-btn__addpatient"
            >
              {t("cancel")}
            </button>
          </div>
          <div className="">
            <button
              className="btn btn-primary text-light px-5 my-1 mx-1"
              onClick={onDone}
              id="done-btn__addpatient"
            >
              {t("done")}
            </button>
          </div>
        </div>
      </div>
    </div>

      <OptForSearch
        showModal={showOptSearchModel}
        setpCode={setpCode}
        setShowOptSearchModel={setShowOptSearchModel}
        pCodeRef={pCodeRef}
        setpCodeX={setpCodeX}
      />
    </>
  );
};

export default PatientSelfRegister;

function PasswordInput({
  inputHandler,
  placeholder,
  error,
  errorMessage,
  id,
  required,
  label,
  value,
  slim = false,
  showLabel = false,
}) {
  const [showPassword, setshowPassword] = useState(false);
  const style = {
    inputContainer: {
      width: "100%",
      border: error ? "2px solid #f00" : "3px solid #acd4ff",
      borderRadius: 4,
      overflow: "hidden",
      height: 50,
      transition: "all .3s ease-in-out",
    },
    input: {
      flexGrow: 1,
      padding: 15,
      outline: "none",
      border: "unset",
    },
    icon: {
      background: "transparent",
      padding: "13px 5px 15px 0",
      textAlign: "center",
      width: "12%",
      height: "100%",
    },
  };

  return (
    <div className={`my-${slim ? "2" : "4"}`}>
      {showLabel && (
        <div>
          <div className="d-inline-block pg-text__bold textc-primary">
            {label}
          </div>
          {required && (
            <span className="textc-primary fst-italic"> (Required)</span>
          )}
        </div>
      )}
      <div>
        <div className="w-90 d-flex" style={style.inputContainer}>
          <input
            placeholder={placeholder}
            type={showPassword ? "text" : "password"}
            onInput={inputHandler}
            style={style.input}
            id={id}
            value={value}
          />
          <div
            style={style.icon}
            className="cursor-pointer transition-all"
            onClick={() => setshowPassword(!showPassword)}
          >
            <i
              className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}
              style={{ color: "#000", fontSize: 16 }}
            ></i>
            {/* <img src={showPassword ? v_on : v_off} alt="Eye Icon"/> */}
          </div>
        </div>
        <div
          className="text-danger mx-2 my-1 transition-all"
          style={error ? { opacity: 1 } : { opacity: 0 }}
        >
          <i className="fas fa-info-circle"></i> {errorMessage}
        </div>
      </div>
    </div>
  );
}
