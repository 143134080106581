import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, useAccordionToggle } from "react-bootstrap";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import FilterIcon from "../../assets/filterIcon.png";
import PatientListTable from "../../components/Practice/PatientList/PatientListTable";
import TableFooter from "../../components/Practice/PatientList/TableFooter";
import FilterComponent from "../../components/Practice/PatientList/FilterComponent";
// import ResendAllPendinInvites from "../../components/Practice/PatientList/ResendAllPendinInvites";
import SuccessAlertComponent from "../../components/Practice/PatientList/SuccessAlert";
import * as UI from "../../store/actions/ui";
import TextInputWithIcon from "../../components/TextInputWithIcon";
import L_PatientList from "../../components/LoadingEffectComponent/L_PatientList";
import { sortArray } from "../../methods";
import {
  getPatientList,
  resendAllPendingInvites,
  SET_PAGE_SIZE,
  updatePatientList,
} from "../../store/actions/patientList";
import { updatePatientReport } from "../../store/actions/patientReport";
import PgInfoTooltip from "../../components/PgInfoTooltip";
import Popup from "reactjs-popup";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { t } from "i18next";

function PatientList() {
  const navigate = useNavigate();
  const location = useLocation();
  // redux state variable
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const language = useSelector((state) => state.auth.language);
  // const listLoading=useSelector(state=>state.patientList.loading);
  // const patient=useSelector(state=>state.patientList.patient);
  // const error=useSelector(state=>state.patientList.error);
  const pageSize = useSelector((state) => state.patientList.pageSize);
  const totalData = useSelector((state) => state.patientList.totalData);
  const canShowAPDialog = useSelector(
    (state) => state.ui.canShowAddPatientDialog
  );

  const dispatch = useDispatch();

  // redux actions
  const setPageSize = (count) =>
    dispatch({ type: SET_PAGE_SIZE, count: count });
  const updatePatientListAction = (data) => dispatch(updatePatientList(data));
  const resendAllPendingInvites = (token, currentPracticeID, currentPractice) =>
    dispatch(
      resendAllPendingInvites(token, currentPracticeID, currentPractice)
    );
  const hideAPDialog = () => dispatch({ type: UI.HIDE_ADD_PATIENT_DIALOG });
  const updatePatientReportAction = (data) =>
    dispatch(updatePatientReport(data));

  // token, currentPracticeID, currentPractice, url
  const { t, i18n } = useTranslation();

  const [recentURL, setRecentUrl] = useState("");
  const {
    isLoading,
    data: __data,
    refetch,
  } = useQuery(
    "patientList",
    () => getPatientList(token, currentPracticeID, currentPractice, recentURL),
    { refetchInterval: 7000 }
  );

  const [showSuccess, setshowSuccessAlert] = useState(canShowAPDialog);
  // const [showPrompt, setshowPrompt] = useState(false);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [filterSignupPending, setfilterSignupPending] = useState(false);
  const [filterNoData, setfilterNoData] = useState(false);
  const [filterNotReviewed, setfilterNotReviewed] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  const [sortReport, setSortReport] = useState("");
  const [sortName, setSortName] = useState("");
  const [sortReportStatus, setSortReportStatus] = useState("");
  const [sortPatientStatus, setSortPatientStatus] = useState("");

  const [patient, setPatient] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    console.log(__data, "patient list");
    setPatient(__data?.data?.patients);
    if (isLoading) setLoading(true);
    else setLoading(false);
  }, [isLoading, __data]);

  const nextPage = () => {
    if (pageCount === pageIndex + 1) setCanNextPage(false);
    if (pageIndex < pageCount) {
      setPageIndex(pageIndex + 1);
      setCanPreviousPage(true);
    }
  };

  const previousPage = () => {
    if (pageIndex === 2) setCanPreviousPage(false);
    if (pageIndex > 1) {
      setCanNextPage(true);
      setPageIndex(pageIndex - 1);
    }
  };

  useEffect(() => {
    setCanPreviousPage(false);
    setPageIndex(1);
  }, [filterNoData, filterNotReviewed, filterSignupPending, searchText]);

  useEffect(() => {
    console.log("alert Message", location);
    if (showSuccess)
      setTimeout(() => {
        setshowSuccessAlert(false);
        hideAPDialog();
      }, 2000);
    // setControlledPageCount(Math.ceil(totalData / pageSize));
  }, [showSuccess, loading]);

  useEffect(() => {
    console.log("recentURL", recentURL);
  }, [recentURL]);

  const fetchTypedPatient = (e) => {
    if (e) e.preventDefault();
    if (searchText.length) setToggleSearch(!toggleSearch);
  };

  // const resendInvite = async () => {
  //   const response = await resendAllPendingInvites(
  //     token,
  //     currentPracticeID,
  //     currentPractice
  //   );
  //   setshowPrompt(false);
  //   if (response.error) toast.error("Unable to send invitations");
  //   else toast.success(response.payload.success);
  // };

  const resetSort = (column) => {
    setSortName(column === "name" ? sortName : "");
    setSortReport(column === "report" ? sortReportStatus : "");
    setSortPatientStatus(column === "patient-status" ? sortPatientStatus : "");
    setSortReportStatus(column === "report-status" ? sortReportStatus : "");
  };

  const continueSort = (url) => {
    console.log(url, "continuing sort");
    let rURL;
    if (sortName) {
      rURL = `${url}&sort_key=last_name&sort_value=${sortName}`;
    } else if (sortReport) {
      rURL = `${url}&sort_key=report_generated_at&sort_value=${sortReport}`;
    } else if (sortPatientStatus) {
      rURL = `${url}&sort_key=aasm_state&sort_value=${sortPatientStatus}`;
    } else if (sortReportStatus) {
      rURL = `${url}&sort_key=viewed&sort_value=${sortReportStatus}`;
    } else rURL = url;
    setRecentUrl(rURL);
    __updatePatientList(rURL);
  };

  const sortPatientData = (column) => {
    const params = new URLSearchParams(recentURL);
    params.delete("sort_key");
    params.delete("sort_value");
    let url = params.toString();
    resetSort(column);
    let rURL;
    if (column === "name") {
      setSortName(
        sortName === "" ? "DESC" : sortName === "DESC" ? "ASC" : "DESC"
      );
      rURL = `${url}&sort_key=last_name&sort_value=${sortName === "" ? "DESC" : sortName === "DESC" ? "ASC" : "DESC"
        }`;
    } else if (column === "report") {
      setSortReport(
        sortReport === "" ? "DESC" : sortReport === "DESC" ? "ASC" : "DESC"
      );
      rURL = `${url}&sort_key=report_generated_at&sort_value=${sortReport === "" ? "DESC" : sortReport === "DESC" ? "ASC" : "DESC"
        }`;
    } else if (column === "patient-status") {
      setSortPatientStatus(
        sortPatientStatus === ""
          ? "DESC"
          : sortPatientStatus === "DESC"
            ? "ASC"
            : "DESC"
      );
      rURL = `${url}&sort_key=display_status&sort_value=${sortPatientStatus === ""
          ? "DESC"
          : sortPatientStatus === "DESC"
            ? "ASC"
            : "DESC"
        }`;
    } else if (column === "report-status") {
      setSortReportStatus(
        sortReportStatus === ""
          ? "DESC"
          : sortReportStatus === "DESC"
            ? "ASC"
            : "DESC"
      );
      rURL = `${url}&sort_key=viewed&sort_value=${sortReportStatus === ""
          ? "DESC"
          : sortReportStatus === "DESC"
            ? "ASC"
            : "DESC"
        }`;
    }
    setRecentUrl(rURL);
    __updatePatientList(rURL);
  };

  const clearFilter = () => {
    setfilterNotReviewed(false);
    setfilterSignupPending(false);
    setfilterNoData(false);
  };

  // const sortedPatientList=(patient)=>{
  //   let dPatientList=[...patient]
  //   if(sortName){
  //     sortArray(patient, "full_name", sortName),
  //   }
  // }

  const __updatePatientList = (url = recentURL) => {
    setLoading(true);
    getPatientList(token, currentPracticeID, currentPractice, url)
      .then((response) => {
        setError(false);
        setLoading(false);
        console.log("response--->", response);
        setPatient(response.data.patients);
        // if (!response.error) {
        // if (sortName) patient = sortArray(patient, "full_name", sortName);
        // if (sortReport)
        //   patient = sortArray(patient, "report_generated_at", sortReport);
        const _PageCount = Math.ceil(response.data.total / pageSize);
        setPageCount(_PageCount);
        console.log("PageIndex:", pageIndex, "_pageCount", _PageCount);
        if (pageIndex < _PageCount) {
          setCanNextPage(true);
        } else {
          setCanNextPage(false);
        }
        // }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        toast.error("Unable to fetch Patient List");
      });
  };

  useEffect(() => {
    let url = `per_page=${pageSize}&page=${pageIndex}`;
    if (searchText.trim().length !== 0) {
      url += `&full_name=${searchText}`;
    }
    if (filterNoData) url += `&no_data=true`;
    if (filterSignupPending) url += `&invitation_accepted_at=true`;
    if (filterNotReviewed) url += `&not_reviewed=true`;
    setRecentUrl(url);
    continueSort(url);
    // __updatePatientList(url);
  }, [
    filterNotReviewed,
    filterSignupPending,
    filterNoData,
    toggleSearch,
    pageSize,
    pageIndex,
    searchText,
  ]);

  const collapseAddBtns =
    (filterSignupPending || filterNoData || filterNotReviewed) &&
    showFilterOption;

  return (
    <div className="container-fluid">
      <Helmet>
        <title>
          {t("puregenomics_1")} - {t("txt_patient_list")}
        </title>
      </Helmet>
      {/* Head section */}
      <div className="container-xxl py-5 px-0 pg-text">
        <div className="position-relative">
          <span
            id="gtd__patientlist"
            className="d-inline-block cursor-pointer textc-primary pg-link"
            onClick={() => navigate("/practice/dashboard")}
          >
            <i className="fas fa-chevron-left "></i> {t("dashboard")}
          </span>
          {showSuccess && (
            <SuccessAlertComponent
              text={location.state?.successMessage}
              top="0%"
              right="10%"
            />
          )}
        </div>
        <div className=" my-4 mt-5 pg-title">{t("patients")}</div>
        <span
          className="pg-text"
          dangerouslySetInnerHTML={{ __html: t("pracpl_line_1") }}
        />
        {i18n.language !== "en_gb" && (
          <PgInfoTooltip style={{ padding: "12px", width: "auto" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: t("patient_list_tooltip"),
              }}
            />
          </PgInfoTooltip>
        )}
        <div className="mt-5">
          <div className="my-5">
            <Accordion>
              <div className="w-100 d-flex justify-content-between px-1 px-xl-0 ">
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <div className="d-flex" style={{ width: "65%" }}>
                    <div className="mx-2 w-75">
                      <TextInputWithIcon
                        label="Patient Name"
                        required={false}
                        value={searchText}
                        inputHandler={(e) => setSearchText(e.target.value)}
                        fetchTypedPatient={fetchTypedPatient}
                        placeholder={t("pracpl_line_2")}
                        type="search"
                        id="search-patient__patientlist"
                        Icon={
                          <i
                            className="fa fa-search text-secondary"
                            aria-hidden="true"
                          ></i>
                        }
                      />
                    </div>
                    <div
                      className="d-flex justify-content-between mt-2"
                      style={{
                        minWidth: "10%",
                        // borderBottom: showFilterOption && "0.5px solid #808080",
                      }}
                    >
                      <CustomToggle
                        showFilterOption={showFilterOption}
                        setShowFilterOption={setShowFilterOption}
                        eventKey="0"
                        t={t}
                      />
                      {(filterSignupPending ||
                        filterNoData ||
                        filterNotReviewed) &&
                        showFilterOption && (
                          <div>
                            <span
                              className="pg-link mt-2"
                              onClick={clearFilter}
                              id="reset-filter__patientlist"
                              style={{ minWidth: "150px" }}
                            >
                              <i
                                className="fa fa-times-circle textc-primary me-2"
                                aria-hidden="true"
                              ></i>
                              {t("reset")}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className={i18n.language === "fr" ? "" : ""}>
                    <div className={`d-flex gap-2 flex-column`}>
                      <button
                        id="add-patient-btn__patientlist"
                        className="btn btn-outline add-new-patient-btn"
                        onClick={() =>
                          navigate("/practice/patient/add", {
                            state: { from: "patientList" },
                          })
                        }
                      >
                        <i className="fas fa-plus mx-1"></i> {t("pracd_btn_2")}
                      </button>
                      <button
                        id="add-patient-btn__patientlist"
                        className={`btn btn-outline  mult-patient-btn`}
                        onClick={() =>
                          navigate("/practice/patient/addbulk", {
                            state: { from: "patientList" },
                          })
                        }
                      >
                        <i className="fas fa-plus mx-1"></i>{" "}
                        {t("pat_bulk_up_add")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Accordion.Collapse eventKey="0" as="div">
                  <FilterComponent
                    filterSignupPending={filterSignupPending}
                    filterNoData={filterNoData}
                    filterNotReviewed={filterNotReviewed}
                    setfilterNoData={setfilterNoData}
                    setfilterNotReviewed={setfilterNotReviewed}
                    setfilterSignupPending={setfilterSignupPending}
                    t={t}
                  />
                </Accordion.Collapse>
              </div>
            </Accordion>
          </div>
          {loading ? (
            <L_PatientList />
          ) : (
            <div>
              {/* <BlockUi tag="div" blocking={loading}> */}
              <PatientListTable
                patients={patient}
                error={error}
                updatePatientReport={updatePatientReportAction}
                sortReport={sortReport}
                sortName={sortName}
                sortReportStatus={sortReportStatus}
                sortPatientStatus={sortPatientStatus}
                sort={sortPatientData}
                canShowAPDialog={canShowAPDialog}
                updatePatientList={__updatePatientList}
              />
              {/* </BlockUi> */}
              <TableFooter
                pageSize={pageSize}
                setPageSize={setPageSize}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageIndex={pageIndex}
                pageCount={pageCount}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default PatientList;

function CustomToggle({
  children,
  eventKey,
  setShowFilterOption,
  showFilterOption,
  t,
}) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  return (
    <span
      className="font-weight-bold cursor-pointer my-2"
      onClick={(e) => {
        decoratedOnClick(e);
        setShowFilterOption(!showFilterOption);
      }}
         style={{ minWidth: "100px" }}
    >
      <span className="pg-link">
        {/* <img src={FilterIcon} alt="" className="me-2 mt-1" /> */}
        <i
          className="fa fa-chevron-down textc-primary me-2"
          aria-hidden="true"
        ></i>
        {t("filter")}
      </span>
    </span>
  );
}

const PatientListTooltip = () => {
  const { t } = useTranslation();
  return (
    <Popup
      on="hover"
      trigger={(open) => (
        <InfoIcon
          stroke="var(--primary)"
          height={30}
          width={30}
          className="cursor-pointer"
          style={{
            marginLeft: "4px",
            cursor: "pointer",
          }}
        />
      )}
      position="left center"
      closeOnDocumentClick
    >
      <div className="p-" style={{ padding: "12px" }}></div>
    </Popup>
  );
};
