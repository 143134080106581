import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import L_PatientActivityTile from "../../LoadingEffectComponent/Dashboard/Practice/L_PatientActivityTile";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function PatientTile({ updatePatientReport, content, loading }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isStaff = useSelector((state) => state.auth.isStaff);
  const showSRPTable = useSelector((state) => state.auth.showSRPTable);
  const language = useSelector((state) => state.auth.language);
  const [mainContent, setmainContent] = useState({
    stats: {
      genetics_data: "0%",
      pending_signups: 0,
      results_ready: "0%",
      total_patients: 0,
    },
    activities: {
      activity_1: {
        link: "#",
        link_text: "Link",
        text: "Getting status...",
      },
      activity_2: {
        link: "#",
        link_text: "Link",
        text: "Getting status...",
      },
      activity_3: {
        link: "#",
        link_text: "Link",
        text: "Getting status...",
      },
    },
  });

  useEffect(() => {
    if (!loading) {
      setmainContent({
        stats: content?.stats,
        activities: content?.activities,
      });
    }
  }, [loading]);

  const goToReportPage = (patientId, reportId) => {
    // updatePatientReport({
    //   currentPatientId: patientId,
    //   currentReportId: reportId,
    // });
    navigate(`/practice/report/new/${reportId}`);
  };

  return (
    <div
      className="pg-card px-0"
      style={{ minHeight: 875, backgroundColor: "#14328c" }}
    >
      {loading ? (
        <div className="p-3 px-4">
          <L_PatientActivityTile />
        </div>
      ) : (
        <div className="p-3 px-4">
          <div className="pg-heading text-white text-center my-2">
            {t("patients")}
          </div>
          <div className="mt-2 my-3">
            <div className="mt-3 pg-text__bold text-white mb-3">
              {t("pracd_ra")}
            </div>
            {Object.entries(mainContent.activities || {}).length === 0 && (
              <div className="d-flex justify-content-between pg-text text-white text-center">
                <div>{t("pracd_nodata")}</div>
              </div>
            )}
            {Object.entries(mainContent.activities || {}).map((data, i) => {
              return (
                <div key={i}>
                  <div className="d-flex justify-content-between pg-text text-white my-1">
                    <div>{data[1].text}</div>
                    <div
                      className="text-start text-end w-md-100"
                      style={{ width: "30%" }}
                    >
                      <div
                        className="pg-link d-inline-block my-1"
                        id={`view-results__dashboard__${i + 1}`}
                        onClick={() =>
                          goToReportPage(data[1].patient_id, data[1].report_id)
                        }
                        style={{ color: "#b7dbff", lineHeight: "20px" }}
                      >
                        {data[1].link_text}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ borderBottom: "1px solid #fff" }} />
          <div className="pg-text__bold text-white my-2">{t("pracd_stat")}</div>
          <div className="d-flex">
            <div className="pg-heading__count">
              {mainContent?.stats?.total_patients}
            </div>
            <div className="pg-text__patient">{t("pracd_tp")}</div>
          </div>
          <div className="d-flex">
            <div className="pg-heading__count">
              {mainContent?.stats?.pending_signups}
            </div>
            <div className="pg-text__patient">{t("pracd_psu")}</div>
          </div>
          <div className="d-flex">
            <div className="pg-heading__count">
              <div className="float-end" style={{ height: 45, width: 45 }}>
                <CircularProgressbar
                  strokeWidth={12}
                  styles={{
                    path: {
                      stroke: `#b7dbff`,
                    },
                    trail: {
                      stroke: "#fff",
                    },
                    text: { fill: "#fff", fontSize: 28 },
                  }}
                  value={mainContent?.stats?.genetics_data}
                  text={`${mainContent?.stats?.genetics_data}%`}
                />
              </div>
            </div>
            <div className="pg-text__patient my-auto">{t("pracd_gdu")}</div>
          </div>
          <div className="d-flex">
            <div className="pg-heading__count">
              <div className="float-end" style={{ height: 45, width: 45 }}>
                <CircularProgressbar
                  strokeWidth={12}
                  styles={{
                    path: {
                      stroke: `#b7dbff`,
                    },
                    text: { fill: "#fff", fontSize: 28 },
                    trail: {
                      stroke: "#fff",
                    },
                  }}
                  value={mainContent?.stats?.results_ready}
                  text={`${mainContent?.stats?.results_ready}%`}
                />
              </div>
            </div>
            <div className="pg-text__patient my-auto">{t("pracd_rrtbw")}</div>
          </div>
        </div>
      )}
      <div className="mt-5 pg-card__btn px-3 px-xl-5 w-100 d-flex flex-column  gap-0">
        <button
          className="btn btn-light px-5 d-block my-2 w-100"
          style={{ width: 350 }}
          onClick={() => navigate("/practice/patients")}
          id="view-patient-btn__dashboard"
        >
          {t("pracd_btn_1")}
        </button>
        <button
          className="btn btn-outline__invert px-3 d-block mx-auto my-2 w-100"
          style={{ width: 350 }}
          id="add-patient-btn__dashboard"
          onClick={() =>
            navigate("/practice/patient/add", { state: { from: "dashboard" } })
          }
        >
          <i className="fas fa-plus mx-1"></i> {t("pracd_btn_2")}
        </button>
        <button
          className="btn btn-outline__invert px-3 d-block mx-auto my-3 w-100"
          style={{ width: 350 }}
          id="add-patient-btn__dashboard"
          onClick={() =>
            navigate("/practice/patient/addbulk", {
              state: { from: "dashboard" },
            })
          }
        >
          <i className="fas fa-plus mx-1"></i> {t("pat_bulk_up_add")}
        </button>

        {(language === "ca" || language === "fr") &&
          showSRPTable &&
          !isStaff && (
            <button
              className="btn btn-outline__invert px-3 d-block mx-auto my-2 w-100"
              style={{ width: 350 }}
              id="add-patient-btn__dashboard"
              onClick={() =>
                navigate("/practice/patient/approval", {
                  state: { from: "dashboard" },
                })
              }
            >
              {t("self_reg_patients")}
            </button>
          )}
      </div>
    </div>
  );
}
